<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-row justify="center">
    <v-snackbar top
                v-model="snackbar" :color="color"
                timeout="2000"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          X
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12" class="px-10">
      <v-card-title class="text-center">
        <span class="headline">Insert a new written exam</span>
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="takeAction">
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
          >
            <v-row>
              <v-col cols="6">
                <v-switch
                    v-model="is_paid"
                    label="Paid"
                    color="primary"
                ></v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch
                    v-model="privacy"
                    label="Private"
                    color="primary"
                ></v-switch>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
              >
                <v-select
                    :items="course.topics"
                    item-text='name'
                    item-value="id"
                    label="Select topic"
                    v-model="topic_id"
                ></v-select>
              </v-col>
              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                >
                  <v-text-field
                      v-model="title"
                      :error-messages="errors"
                      label="Exam title"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="TotalMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="total_mark"
                      :error-messages="errors"
                      label="Total Mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="PassMark"
                    rules="required"
                >
                  <v-text-field
                      v-model="pass_mark"
                      :error-messages="errors"
                      label="Pass mark"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
              >
                <validation-provider
                    v-slot="{ errors }"
                    name="Duration"
                    rules="required"
                >
                  <v-text-field
                      v-model="duration"
                      :error-messages="errors"
                      label="Exam Duration (in minute)"
                      required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col
                  cols="12"
              >
                <tiptap-vuetify
                    v-model="description"
                    :extensions="extensions"
                    placeholder="Enter exam description"
                >
                </tiptap-vuetify>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-datetime-picker
                    ref="availableAtRef"
                    v-model="available_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                  cols="12"
                  sm="12">
                <v-datetime-picker
                    ref="endsAtRef"
                    v-model="ends_at"
                    :text-field-props="{prependIcon: 'mdi-calendar'}"
                    label="Answer available at"
                    time-format="hh:mm:ssa"
                >
                  <template slot="dateIcon">
                    <v-icon>mdi-calendar</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>mdi-clock</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-btn
                  class="mr-4"
                  type="submit"
                  :disabled="invalid"
                  :loading="loading"
              >
                {{ editMode ? 'update' : 'submit' }}
              </v-btn>
              <v-btn @click="clear">
                clear
              </v-btn>
            </v-row>
          </validation-observer>
        </form>
      </v-card-text>
    </v-col>
  </v-row>
</template>
<script>
import {required} from 'vee-validate/dist/rules'
import axios from 'axios'
import {extend, ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import moment from 'moment'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  props: ['course'],
  components: {
    ValidationProvider,
    ValidationObserver,
    TiptapVuetify,
  },
  data: () => ({
    allowDescription: true,
    loading: false,
    selectedExam: null,
    editMode: false,
    dialog: false,
    title: '',
    description: '',
    topic_id: '',
    snackbar: false,
    total_section: null,
    text: '',
    color: 'blue',
    is_paid: 0,
    privacy: false,
    available_at: '',
    ends_at: '',
    duration: '',
    total_mark: '',
    pass_mark: '',
    strict: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  computed: {
    availableAt() {
      return moment(this.available_at).isValid() ? moment(this.available_at).format() : '';
    },
    endsAt() {
      return moment(this.ends_at).isValid() ? moment(this.ends_at).utc('utc').format() : '';
    },
    form: function () {
      let formData = new FormData();
      if (this.course) {
        formData.append('course_id', this.course.id);
      }
      if (this.editMode) {
        formData.append('_method', 'PUT')
      }
      formData.append('title', this.title)
      formData.append('description', this.description ? this.description : '')
      formData.append('topic_id', this.topic_id)
      formData.append('privacy', this.privacy ? 'private' : 'public')
      formData.append('is_paid', this.is_paid ? 1 : 0)
      formData.append('available_at', this.availableAt)
      formData.append('total_mark',this.total_mark)
      formData.append('pass_mark', this.pass_mark)
      formData.append('duration', this.duration)
      formData.append('ends_at', this.endsAt)
      return formData;
    }
  },
  methods: {
    takeAction() {
      this.editMode ? this.update() : this.submit();
    },
    edit(exam) {
      this.editMode = true;
      this.title = exam.title;
      this.description = exam.description;
      this.topic_id = exam.content.topic_id;
      this.total_mark = exam.total_mark
      this.pass_mark = exam.pass_mark
      this.selectedExam = exam;
      this.is_paid = exam.is_paid;
    },
    submit() {
      const url = 'admin/written-exam';
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'New exam created';
        this.loadExams();
      })
    },
    update() {
      const url = 'admin/written-exam/' + this.selectedExam.id;
      this.loading = true;
      axios.post(url, this.form).then(() => {
        this.loading = false;
        this.clear();
        this.color = 'green';
        this.snackbar = true;
        this.text = 'Exam updated';
        this.loadExams();
      })
    },
    loadExams() {
      this.$emit('updated');
    },
    clear() {
      this.title = ''
      this.description = ''
      this.topic_id = ''
      this.selectedExam = null
      this.available_at = ''
      this.total_mark = ''
      this.pass_mark = ''
      this.privacy = false
      this.$refs.availableAtRef.clearHandler();
      this.$refs.endsAtRef.clearHandler();
      this.$refs.observer.reset();
      this.duration = '';
    },
  },
}

setInteractionMode('eager');
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})
</script>